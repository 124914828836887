import * as React from 'react';
import Layout from '../components/Layout';
import { Box, Container, Link, Stack, Typography } from '@mui/material';
import Header from '../components/Header';
import InfoSection from '../components/InfoSection';
import Footer from '../components/Footer';
import { graphql } from 'gatsby';

type ContactProps = {
  data: GatsbyTypes.ContactNoticeQuery;
}

export default function Contact({ data }: ContactProps): JSX.Element {
  const [email, setEmail] = React.useState({isSet: false, address: ''});
  React.useEffect(() => {
    const obfEmail = ['ocleeandson.co.uk', '@', 'contact'];
    setEmail({isSet: true, address: obfEmail.reverse().join('')});
  }, []);

  const noticeText = data.allStrapiCategories.nodes.length === 1 ? data.allStrapiCategories.nodes[0].description.trim() : null;
  const notice = noticeText ?
    <Typography variant="body1" maxWidth="34em">
      <Box component="span" fontWeight="fontWeightMedium">Customer notice: </Box>
      { noticeText }
    </Typography>
    :
    null;

  return (
    <Layout title="Contact Us | Oclee &amp; Son">
      <Stack minHeight="100vh">
        <Header page="contact" />
        <Box flex={1} mb={4}>
          <Container>
            <Typography variant="h1" gutterBottom>Contact Us</Typography>
            <Typography variant="body1" gutterBottom>
              We&apos;d love to hear from you.
            </Typography>
            <Typography variant="body1" mb={3}>
              Give us a call, email, or come visit us in store:
            </Typography>
            <Stack direction="column" gap={3}>
              <InfoSection title="Telephone">
                <Typography variant="body1">
                  01303 252654
                </Typography>
              </InfoSection>
              <InfoSection title="Email">
                {
                  email.isSet
                    ? <Link href={`mailto:${email.address}`} underline="always">
                        {email.address}
                      </Link>
                    : <Typography variant="body1">
                        Please enable JavaScript
                      </Typography>
                }
              </InfoSection>
              <InfoSection title="Store opening times">
                <Typography variant="body1" gutterBottom={notice ? true : false}>
                  Tuesday - Saturday: 10:00 - 16:30
                  <br />
                  Sunday - Monday: closed
                </Typography>
                { notice }
              </InfoSection>
              <InfoSection title="Address">
                <Typography variant="body1" mb={3}>
                  35 Sandgate Road
                  <br />
                  Folkestone
                  <br />
                  Kent
                  <br />
                  CT20 1SB
                </Typography>
                <Box maxWidth={['100%', '550px']} height="400px">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d331.9530916099054!2d1.1797228851988353!3d51.079380352438555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47debed0073f55d7%3A0x1737f6462e053e5f!2sOclee%20%26%20Son%20Ltd!5e0!3m2!1sen!2suk!4v1633470584486!5m2!1sen!2suk"
                    title="Location of Oclee &amp; Son on Google Maps"
                    style={{ border: 0, width: '100%', height: '100%' }}
                    allowFullScreen={false}
                    loading="lazy"
                  />
                </Box>
              </InfoSection>
            </Stack>
          </Container>
        </Box>
        <Footer />
      </Stack>
    </Layout>
  );
}

export const query = graphql`
query ContactNotice {
  allStrapiCategories(
    filter: {
      name: {eq: "Oclee & Son Ltd"}
    }
  ) {
    nodes {
      description
    }
  }
}
`;
