import * as React from 'react';
import { Box, Typography } from '@mui/material';

type InfoSectionProps = {
  title: string;
  children: React.ReactNode;
};

export default function InfoSection({title, children}: InfoSectionProps): JSX.Element {
  return (
    <Box>
      <Typography variant="h4" component="h2">{title}</Typography>
      {children}
    </Box>
  );
}
